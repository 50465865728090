<template>
    <div class="h-100" style="min-width: 780px;">
        <b-container fluid class="h-100 d-flex flex-column">
            <b-row class="m-0">
                <b-col class="text-center p-0">
                    <h1>App Betweenness centrality demo BETA</h1>
                </b-col>
            </b-row>
            <b-row class="flex-grow-1 justify-content-center m-0 vh-100">
                <GridBar />
            </b-row>
            <b-row class="justify-content-end">
                <b-col class="text-right">
                    <button @click="exitDemo" class="btn btn-primary mr-3 mb-2">Exit demo</button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import GridBar from "./GridBar.vue";

export default {
    components: {
        GridBar,
    },
    data: function () {
        return {

        }
    },
    mounted: function () {
        console.log(`the component is now mounted.`);
    },
    methods: {
        testFirebase: function () {
            console.log(this.tests);
        },
        exitDemo() {
            document.cookie = "refreshToken" + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            document.cookie = "accessToken" + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            this.$router.push("/welcome");
        }
    },
}
</script>
    