<template>
    <div>
        <div>
            To import a graph, you need to import a zip file containing 4 files :
            <div>- edges-header.csv</div>
            <div>- edges.csv</div>
            <div>- nodes-header</div>
            <div>- nodes.csv</div>
            The name of the zip file is important: it will be used as the id in our database, so if you choose a name that's
            too common it could be rejected.
            <div>Bear in mind that your graph will be visible to other users.</div>

        </div>
        <div style="margin-top:25px">
            <h4> Description </h4>
            <textarea v-model="description" class="col" placeholder="Write a description about your graph"></textarea>
        </div>

        <div class="container-btn-upload">
            <input id="fileInput" type="file" @change="handleFileUpload">
            
            <b-button class="btn-secondary" @click="uploadFile">Upload</b-button>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            selectedFile: null,
            description: null
        };
    },
    methods: {
        handleFileUpload(event) {
            this.selectedFile = event.target.files[0];
        },
        uploadFile() {
            if(!this.description){
                alert("write a description about your graph before uploading")
                return
            }

            if (this.selectedFile) {
                // Créez une instance de FormData pour envoyer le fichier
                const formData = new FormData();
                formData.append('file', this.selectedFile);
                formData.append('description', this.description);
                console.log(formData)
                // Effectuez une requête POST pour uploader le fichier
                if (document.cookie.indexOf("accessToken") !== -1) {
                    var accessToken = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('accessToken='))
                        .split('=')[1];
                } else {
                    accessToken = ""
                }

                fetch('api.php?run=upload', {
                    headers: { 'Authorization': 'Bearer ' + accessToken },
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        return response.json()
                    })
                    .then( data => {
                        console.log("data : ")
                        console.log(data)
                        if (data["upload"] == "failed") {
                            alert(data["reason"])
                        } else {
                            alert(`file successfully uploaded : ${data["nodes"]} nodes, ${data["edges"]} edges`)    
                        }
                        this.selectedFile = null;

                    })
                    .catch(error => {
                        console.error('Error :', error);
                    });
                document.querySelector("#fileInput").value = ""
            }
        }
    }
}
</script>

<style>
.container-btn-upload {
    margin-top: 10%;
    justify-content : space-around;
    display: flex;
}
</style>