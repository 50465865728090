export const status = {
    Running: "running",
    Waiting: "waiting",
    Done: "done",
    Error: "error",
    Empty: ""
  };

export const check = {
    Ok: 0,
    Error: 1,
    Empty: 2
  };
  
export function timeString(timeSeconds) {
    let hours = Math.floor(timeSeconds / 60 / 60);
    timeSeconds -= hours * 60 * 60;
    let minutes = Math.floor(timeSeconds / 60);
    timeSeconds -= minutes * 60;
    let seconds = Math.floor(timeSeconds);
    if (hours > 0)
        return `${hours}h ${minutes}m ${seconds}s`;
    else if(minutes > 0)
        return `${minutes}m ${seconds}s`;
    else
        return `${seconds}s`;
}
// returns size graph in GB, duplicate of graphSize in Graphs.js
export function size(nodes, edges) {
    return parseInt((nodes * edges) / 1000_000_000);
}
// returns N:I ratio
export function factor(t1, t2) {
    return Math.round(t1 / t2);
}