<template>
    <b-row class="flex-grow-1 justify-content-center m-0">
        <b-col style="flex: 0 0 content">
            <b-tabs pills card justified class="h-100 d-flex flex-column flex-grow-1" style="width: 780px;">
                <b-tab title="Presentation">
                    <GridPresentation />
                </b-tab>
                <b-tab no-body title="Start tests" active>
                    <GridGraphs />
                </b-tab>
                <b-tab no-body title="See results">
                    <GridResults />
                </b-tab>
                <b-tab title="Upload graph">
                    <GridUpload />
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
    
</template>

<script>
import GridResults from "./results/GridResults.vue";
import GridGraphs from "./start/GridGraphs.vue";
import GridPresentation from "./presentation/GridPresentation.vue";
import GridUpload from "./upload/GridUpload.vue";


export default ({
    components: {
        GridResults,
        GridGraphs,
        GridPresentation,
        GridUpload,
    },

})
</script>