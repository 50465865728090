<template>
  <div id="app" class="h-100">
    <router-view/>
  </div>
</template>

<script>
import { getApp } from 'firebase/app';
import Page from './components/GridPage.vue'
import Welcome from './components/GridWelcome.vue'

export default {
  // name: getApp,
  components: {
    Page, Welcome
  },
  data: function () {
    return {

    }
  },
  methods: {
    mounted: function () {
      console.log(`the component is now mounted.`);
    },
    testFirebase: function () {
      console.log(this.tests);
    },
  },
}
</script>

<style>
body,
html {
  height: 100%;
}

.execution-stats-names {
  flex: 0 0 100px !important;
}

.execution-stats-values {
  flex: 1 0 !important;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.btn:active {
  transform: scale(0.95);
}

.tab-content {
  display: flex;
  flex-grow: 1;
}

.card-header {
  padding: 0.5rem 0.75rem !important;
}

.card-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}</style>
