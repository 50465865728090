<template>
    <div class="h-100" style="min-width: 780px;">
            <b-container fluid class="h-100 d-flex flex-column">
                <b-row class="m-0">
                    <b-col class="text-center p-0">
                        <h1>ICY COMPANY</h1>
                    </b-col>
                </b-row>
                <b-row class="flex-grow-1 justify-content-center align-items-center m-0">
                    <GridLoginForm />
                </b-row>

            </b-container>
    </div>
</template>

<script>
import GridLoginForm from './GridLoginForm'
export default {
    components: {
        GridLoginForm
    },
    data: function () {
        return {

        }
    },
    methods: {
    },
    mounted: function () {
        console.log(`the component is now mounted.`);
    },
}
</script>
