<template>
  <div class="h-100">
    <ag-grid-vue :style="{ width, height }" class="ag-theme-alpine" :columnDefs="columnDefs"
      :defaultColDef="defaultColDef" :rowData="rowData" :suppressRowClickSelection="true" :suppressCellFocus="true"
      :rowSelection="rowSelection" :alwaysShowVerticalScroll="true" :suppressHorizontalScroll="true"
      @grid-ready="onGridReady" @cell-clicked="onCellClicked">
    </ag-grid-vue>

    <b-modal id="modal-test" centered modal-footer="ceva" :title="graphSelected.name">
      <b-container>
        <b-row>
          <b-col class="border">Nodes</b-col>
          <b-col class="border">{{ graphSelected.numNodes }}</b-col>
        </b-row>
        <b-row>
          <b-col class="border">Edges</b-col>
          <b-col class="border">{{ graphSelected.numEdges }}</b-col>
        </b-row>
        <b-row>
          <b-col class="border">Size (nodes * edges / 1 bil)</b-col>
          <b-col class="border">{{ graphSelected.size }}</b-col>
        </b-row>
        <b-row>
          <b-col class="border">Source</b-col>
          <b-col class="border"><a :href="graphSelected.sourceLink" target="_blank">{{ graphSelected.sourceName
          }}</a></b-col>
        </b-row>
        <b-row>
          <b-col class="border">Graph data</b-col>
          <b-col class="border">
            <b-button @click="downloadGraph(graphSelected.id)" class="w-100" variant="secondary">Download</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border">Our Neo4j script</b-col>
          <b-col class="border">
            <!-- <b-button @click="downloadScript" class="w-100" variant="secondary">Download</b-button> -->
            <span>Coming soon</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border">Test machine specs</b-col>
          <b-col class="border">
            Ubuntu server 22.04
            <br>
            i7-4790 CPU @ 3.60GHz
            <br>
            32 GB DDR3 1600 MHz
            <br>
            SSD SATA-III
          </b-col>

        </b-row>
        <b-row>
          <b-col class="border">Estimated ICY time</b-col>
          <b-col class="border">{{ graphSelected.estimatedICY }}</b-col>
        </b-row>
        <b-row>
          <b-col class="border">Estimated Neo4j time</b-col>
          <b-col class="border">{{ graphSelected.estimatedNeo4j }}</b-col>
        </b-row>

        <b-row style="margin-top: 10px;">
          <b-col style="text-align: justify;">{{ graphSelected.description }}</b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <b-container class="p-0 m-0">
          <b-row>
            <b-col>
              <p style="font-size: 120%; text-align: center;">
                Compute betweenness centrality for this graph using
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button class="w-100" variant="primary" @click="runNeo4j(graphSelected.id)">
                Neo4j
              </b-button>
            </b-col>
            <b-col>
              <b-button class="w-100" variant="primary" @click="runIcy(graphSelected.id)">
                ICY
              </b-button>
            </b-col>
            <b-col>
              <!-- <b-button class="w-100" variant="primary" @click="show=false"> -->
              <b-button class="w-100" variant="primary" @click="runBoth(graphSelected.id)">
                Neo4j and ICY
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import GridValueRegular from "../utils/GridValueRegular.vue";
import GridValueLink from "./GridValueLink.vue";
// import graphsJson from '../../public/graphs.json';
import { timeString } from "../../common.js";

export default {
  components: {
    AgGridVue,
    GridValueRegular,
    GridValueLink,
  },
  data: function () {
    return {
      columnDefs: [
        { field: "graph", headerName: "Graph", width: 150, cellRendererFramework: GridValueRegular },
        { field: "size", headerName: "Size", width: 70, cellRendererFramework: GridValueRegular },
        { field: "description", headerName: "Description", width: 450, cellRendererFramework: GridValueRegular },
        { field: "source", headerName: "Source", width: 110, cellRendererFramework: GridValueLink },
      ],
      defaultColDef: {
        sortable: true,
        wrapText: true,
        autoHeight: true,
        suppressMovable: true
      },
      width: '800px',
      height: '100%',
      graphsJson: [],
      rowData: null,
      gridApi: null,
      gridColumnApi: null,
      rowSelection: 'single',

      modalWidth: '100%',
      modalHeight: '100%',
      modalDomLayout: 'autoHeight',
      modalColumnDefs: [
        { field: "name", },
        { field: "value", },
      ],
      modalDefaultColDef: {
        suppressMovable: true
      },
      graphSelected: {},
    }
  },
  mounted: function () {
    console.log("mounted")
    console.log(`the component is now mounted.`);
  },
  methods: {
    graphSize: function (x) {
      return parseInt(x.numNodes * x.numEdges / 1000000000);
    },
    onGridReady: function (params) {
      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }
      fetch('api.php?run=graph', {
        headers: { 'Authorization': 'Bearer ' + accessToken }
      })

        .then(response => response.json())
        .then(data => {
          this.graphsJson = data;
          this.rowData = data.map(x => ({
            id: x.id,
            graph: x.name,
            size: this.graphSize(x),
            description: x.descriptionShort,
            source: { name: x.sourceName, link: x.sourceLink }
          }));
          console.log(this.graphsJson)
        });
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      let widthTotal = this.columnDefs.reduce((partialSum, a) => partialSum + a.width, 2);
      this.width = widthTotal + "px";
      // this.rowData = this.graphsJson.map(x => ({
      //   id: x.id,
      //   graph: x.name,
      //   size: this.graphSize(x),
      //   description: x.descriptionShort,
      //   source: { name: x.sourceName, link: x.sourceLink }
      // }));
    },
    onCellClicked: function (params) {
      if (params.column.colDef.cellRendererFramework != GridValueLink) {
        let x = this.graphsJson.find(x => x.id == params.data.id);
        this.graphSelected = {
          id: x.id,
          name: x.name,
          numNodes: x.numNodes,
          numEdges: x.numEdges,
          size: this.graphSize(x),
          sourceName: x.sourceName,
          sourceLink: x.sourceLink,
          estimatedICY: timeString(parseInt(this.graphSize(x) * 1.5)),
          estimatedNeo4j: timeString(parseInt(this.graphSize(x) * 15)),
          description: x.descriptionLong,
        }
        this.$bvModal.show("modal-test");
      }
    },
    runBoth: function (id) {
      console.log(id)
      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }
      fetch('api.php?run=both&graph_id=' + id, {
        headers: { 'Authorization': 'Bearer ' + accessToken },
        method: 'GET',
      })
        .then(response => response.json())
        .then(data => { console.log(data); this.$bvModal.hide("modal-test"); })
        .catch(error => console.error(error));
    },
    runNeo4j: function (id) {
      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }
      fetch('api.php?run=neo4j&graph_id=' + id, {
        headers: { 'Authorization': 'Bearer ' + accessToken },
        method: 'GET',
      })
        .then(response => response.json())
        .then(data => { console.log(data); this.$bvModal.hide("modal-test"); })
        .catch(error => console.error(error));

    },
    runIcy: function (id) {
      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }

      fetch('api.php?run=icy&graph_id=' + id, {
        headers: { 'Authorization': 'Bearer ' + accessToken },
        method: 'GET',
      })
        .then(response => response.json())
        .then(data => { console.log(data); this.$bvModal.hide("modal-test"); })
        .catch(error => console.error(error));

    },
    downloadGraph: function (id) {
      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }
      fetch('api.php?run=dlgraph&graph_id='+id, {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + accessToken },
      })
        .then(response => response.blob())
        .then(blob => {
          // Créer un lien de téléchargement
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', id+'.csv');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error(error);
        });
    },
    downloadScript: function (id) {
      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }
      fetch('api.php?run=dlgraph', {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + accessToken },
      })
        .then(response => response.blob())
        .then(blob => {
          // Créer un lien de téléchargement
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'script.zip');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error(error);
        });
    }

  },
}
</script>

<style>
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

body,
html {
  height: 100%;
}

.execution-stats-names {
  flex: 0 0 100px !important;
}

.execution-stats-values {
  flex: 1 0 !important;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.btn:active {
  transform: scale(0.95);
}
</style>
