<template>
  <div style="display: flex;">
    <div>
      <img :src=image v-show=display_image width="25" height="25" style="margin-left: 8px; margin-right: 10px" />
    </div>
    <div>
      {{ text }}
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import { check, status, timeString } from "../../common.js";

  export default Vue.extend({
    data() {
      return {
        image: "",
        text: "",
        display_image: true,
      }
    },
    created: function() {
        let data = this.selectData(this.params.value);
        this.display_image = data.display_image;
        this.image = data.image;
        this.text = data.text;
      },
    components: {
    },
    methods: {
      selectData(checkCurrent) {
        switch (checkCurrent) {
          case check.Ok:
            return {
              image: 'status-check-ok.png',
              display_image: true,
              text: "Same",
            };
          case check.Error:
            return {
              image: 'status-check-error.png',
              display_image: true,
              text: "Different",
            };
          case check.Empty:
            return {
              display_image: false,
              text: "",
            };
        }
      }
    }
  });
</script>

<style>
</style>