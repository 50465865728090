<template>
    <div>
        <div id="form-container" class="d-flex flex-column align-items-center vh-100">

            <h2 class="mb-4">Connection</h2>

            <form @submit.prevent="login" class="text-center">
                <div class="form-group">
                    <label for="username" class="font-weight-bold">Username :</label>
                    <input type="text" id="username" v-model="username" required class="form-control">
                </div>

                <div class="form-group">
                    <label for="password" class="font-weight-bold">Password :</label>
                    <input type="password" id="password" v-model="password" required class="form-control">
                </div>

                <div>
                    <button type="submit" class="btn btn-primary">Connect</button>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        login() {
            // Vous pouvez ajouter ici la logique d'authentification.
            // Cette logique peut être différente en fonction de votre back-end.
            // Par exemple, vous pouvez faire une requête AJAX à votre back-end.
            console.log('Username: ', this.username);
            console.log('Password: ', this.password);
            fetch('api.php?run=login&password=' + this.password + '&username=' + this.username)
                .then(response => {
                    if (!response) {
                        throw new Error('request failed')
                    }
                    return response.json()
                })
                .then(data => {
                    if (data && data.tokens) {
                        console.log("user connected !")
                        // Pour stocker les tokens dans les cookies
                        document.cookie = `accessToken=${data.tokens.access}`;
                        document.cookie = `refreshToken=${data.tokens.refresh}`;
                        this.$router.push("/");
                    } else {
                        alert("wrong username / password")
                    }
                })
                .catch((e) => {
                    alert("Server is off, please contact a member of our team")
                })
            this.username = '';
            this.password = '';
        },
    },
};
</script>
  
<style scoped>
#form-container {
    position: relative;
    top: 30%;
    transform: (translateY());
    width: 100%;
}
</style>
  