<template>
    <div>
        <h2>About this tool</h2>
        <p>This web page is a tool that shows how fast ICY can compute betweenness centrality.</p>
        <p>To use this tool, go to "Start tests" and click a graph for which you want to compute the betweenness centrality.
            You
            can see the results in "See results". The graphs all come from the <a href="https://snap.stanford.edu/data/"
                target="_blank">Stanford Large Network Dataset Collection</a>. We used all the graphs that are large enough
            to
            take Neo4j more than a few seconds, but small enough to take less than 12 hours. We are also working on letting
            you
            upload your own graphs.</p>
        <p style="font-weight: bold;">WARNING: This is a BETA version. Some features and bug fixes are still in progress.
        </p>
        <h2>Why betweenness centrality</h2>
        <p>Betweenness centrality is a number for each node in a network. This number shows how important the node is in the
            network. It is used to solve problems in many fields including social networks, biology, transport and
            scientific
            cooperation.</p>
        <p>This number is very useful, but it is hard to compute for large networks. In fact, for most practical networks it
            takes too long to compute the precise number using the current algorithms. The solution so far has been to
            compute
            an approximation and to hope that the approximation is good enough.</p>
        <p>What do we mean when we say that a node in a network is important? Depending on what you are trying to solve, you
            may
            be interested in different things. Maybe you want the nodes with the most immediate connections to other nodes.
            Maybe you want the nodes that are hardest to reach. For many problems it turns out that what you want is the
            betweenness centrality for each node.</p>
        <p>Betweenness centrality for a node represents the degree to which a node stands between other nodes. For example,
            in a
            telecommunications network, a node with higher betweenness centrality would have more control over the network,
            because more information will pass through that node.</p>
        <h2>Why Neo4j</h2>
        <p>ICY has found a way to compute this number faster than the current solutions on the market. The purpose of this
            web
            page is to show how fast ICY is compared to other technologies. We chose Neo4j for the comparison as it is the
            most
            popular graph database. They have the incentive and the funding to implement the fastest graph algorithms
            currently
            available.</p>
    </div>
</template>
<script>
export default {}
</script>
<style>
body,
html {
    height: 100%;
}

.execution-stats-names {
    flex: 0 0 100px !important;
}

.execution-stats-values {
    flex: 1 0 !important;
}

.container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.btn:active {
    transform: scale(0.95);
}

.tab-content {
    display: flex;
    flex-grow: 1;
}

.card-header {
    padding: 0.5rem 0.75rem !important;
}

.card-body {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.ag-cell-wrap-text {
    word-break: break-word !important;
}
</style>