<template>
  <div style="margin-left: 12px; font-weight: bold; font-size: 18px;">
      {{ text }}
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    data() {
      return {
        text: "",
      }
    },
    created: function() {
        this.text = `${this.params.value}`;
      },
    components: {
    },
    methods: {
    }
  });
</script>

<style>
</style>