<template>
    <div>
        <section class="navbar-area navbar-nine">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg">

                            <h2 style="color: white !important;">ICY COMPANY</h2>

                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNine" aria-controls="navbarNine" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse sub-menu-bar" id="navbarNine">
                                <ul class="navbar-nav me-auto">
                                    <li class="nav-item">
                                        <a class="page-scroll active" href="#hero-area">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="page-scroll" href="#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>

                        </nav>
                        <!-- navbar -->
                    </div>
                </div>
                <!-- row -->
            </div>
            <!-- container -->
        </section>

        <!-- Start header Area -->
        <section id="hero-area" class="header-area header-eight">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="header-content">
                            <h2 style="color: white !important;">Test our algorithm</h2>
                            <p>
                                Unleash the Full Potential of Network Analysis - Experience the Lightning-Fast Betweenness
                                Centrality Computation Tool and See Your Data in a New Light!
                            </p>
                            <div class="button">
                                <a href="javascript:void(0)" @click="goLoginPage" class="btn primary-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="header-image">
                            <img src="img.png" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End header Area -->

        <!-- Start Cta Area -->
        <section id="call-action" class="call-action">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                        <div class="inner-content">
                            <h2>Why not trying us ?</h2>
                            <p>
                                Discover the Game-Changing Power of ICY - The Ultimate Tool for Lightning-Fast Betweenness
                                Centrality Computation. Experience Unprecedented Speed and Uncover Hidden Insights in Your
                                Networks!
                            </p>
                            <div class="light-rounded-buttons">
                                <a href="javascript:void(0)" @click="goLoginPage" class="btn primary-btn-outline">Get
                                    Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Cta Area -->


        <!-- ========================= contact-section start ========================= -->
        <section id="contact" class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4">
                        <div class="contact-item-wrapper">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-12">
                                    <div class="contact-item">
                                        <div class="contact-icon">
                                            <i class="lni lni-phone"></i>
                                        </div>
                                        <div class="contact-content">
                                            <h4>Contact</h4>
                                            <p>Elie-Ouzi Koskas</p>
                                            <p>elie.koskas@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-12">
                                    <div class="contact-item">
                                        <div class="contact-icon">
                                            <i class="lni lni-alarm-clock"></i>
                                        </div>
                                        <div class="contact-content">
                                            <h4>Schedule</h4>
                                            <p>5 Days Open</p>
                                            <p>Office time: 10 AM - 5:30 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="contact-form-wrapper">
                            <div class="row">
                                <div class="col-xl-10 col-lg-8 mx-auto">
                                    <div class="section-title text-center">

                                        <h2>
                                            Ask access
                                        </h2>
                                        <p>
                                            You are interested in our products. Please contact us!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <form action="/api.php" method="GET" class="contact-form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" name="name" id="name" placeholder="Name" required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="email" name="email" id="email" placeholder="Email" required />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" name="phone" id="phone" placeholder="Phone" required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <textarea name="message" id="message" placeholder="Type Message"
                                            rows="5"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="button text-center rounded-buttons">
                                            <button type="submit" class="btn primary-btn rounded-full">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="run" value="mail" />
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ========================= contact-section end ========================= -->

        <a href="#" class="scroll-top btn-hover">
            <i class="lni lni-chevron-up"></i>
        </a>
    </div>
</template>
  
<script>

import GridLogin from './login/GridLogin'
export default {
    components: {
        GridLogin
    },
    data: function () {
        return {

        }
    },
    methods: {
        goLoginPage: function () {
            this.$router.push("login");
            // window.location.href = "/login"
        }
    },
    mounted: function () {
        console.log(`the component is now mounted.`);
        document.querySelector('.contact-form').addEventListener('submit', function (event) {
            event.preventDefault();

            const formData = new FormData(this);
            formData.append('run', 'mail');

            fetch('/api.php', {
                method: 'POST', // Modifier la méthode en POST
                body: formData
            })
                .then(response => {
                    console.log('Requête envoyée avec succès !');
                    // Gérer la réponse de la requête ici
                })
                .catch(error => {
                    console.error('Une erreur s\'est produite lors de l\'envoi de la requête.', error);
                    // Gérer les erreurs de la requête ici
                });
        });
    },
}
</script>
  
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");


/*===========================
    COMMON css 
  ===========================*/
:root {
    --font-family: "Inter", sans-serif;
    --primary: #155bd5;
    --primary-dark: #1c3ab6;
    --primary-light: #e2f1ff;
    --accent: #00d4d7;
    --accent-dark: #00bac1;
    --accent-light: #dff9f8;
    --success: #13d527;
    --success-dark: #00ae11;
    --success-light: #eafbe7;
    --secondary: #8f15d5;
    --secondary-dark: #6013c7;
    --secondary-light: #f4e5fa;
    --info: #15b2d5;
    --info-dark: #0f8ca8;
    --info-light: #e0f5fa;
    --caution: #dbbb25;
    --caution-dark: #d58f15;
    --caution-light: #fbf9e4;
    --error: #e6185e;
    --error-dark: #bf1257;
    --error-light: #fce4eb;
    --black: #1d1d1d;
    --dark-1: #2d2d2d;
    --dark-2: #4d4d4d;
    --dark-3: #6d6d6d;
    --gray-1: #8d8d8d;
    --gray-2: #adadad;
    --gray-3: #cdcdcd;
    --gray-4: #e0e0e0;
    --light-1: #efefef;
    --light-2: #f5f5f5;
    --light-3: #fafafa;
    --white: #ffffff;
    --gradient-1: linear-gradient(180deg, #155bd5 0%, #1c3ab6 100%);
    --gradient-2: linear-gradient(180deg, #155bd5 13.02%, #00d4d7 85.42%);
    --gradient-3: linear-gradient(180deg, #155bd5 0%, #8f15d5 100%);
    --gradient-4: linear-gradient(180deg, #155bd5 0%, #13d527 100%);
    --gradient-5: linear-gradient(180deg, #155bd5 0%, #15bbd5 100%);
    --gradient-6: linear-gradient(180deg, #155bd5 0%, #dbbb25 100%);
    --gradient-7: linear-gradient(180deg, #155bd5 0%, #e6185e 100%);
    --gradient-8: linear-gradient(180deg, #1c3ab6 0%, #00bac1 100%);
    --gradient-9: linear-gradient(180deg, #00d4d7 13.02%, #155bd5 85.42%);
    --shadow-1: 0px 0px 1px rgba(40, 41, 61, 0.08),
        0px 0.5px 2px rgba(96, 97, 112, 0.16);
    --shadow-2: 0px 0px 1px rgba(40, 41, 61, 0.04),
        0px 2px 4px rgba(96, 97, 112, 0.16);
    --shadow-3: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
    --shadow-4: 0px 2px 4px rgba(40, 41, 61, 0.04),
        0px 8px 16px rgba(96, 97, 112, 0.16);
    --shadow-5: 0px 2px 8px rgba(40, 41, 61, 0.04),
        0px 16px 24px rgba(96, 97, 112, 0.16);
    --shadow-6: 0px 2px 8px rgba(40, 41, 61, 0.08),
        0px 20px 32px rgba(96, 97, 112, 0.24);
}

body {
    font-family: var(--font-family);
    color: var(--black);
    font-size: 16px;
}

@media (max-width: 991px) {
    body {
        font-size: 14px;
    }
}

img {
    max-width: 100%;
}

a {
    display: inline-block;
}

a,
button,
a:hover,
a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    /* color: var(--black); */
    margin: 0px;
}

h1,
.h1 {
    font-size: 2.75em;
    line-height: 1.25;
}

h2,
.h2 {
    font-size: 2.25em;
    line-height: 1.25;
}

h3,
.h3 {
    font-size: 1.75em;
    line-height: 1.25;
}

h4,
.h4 {
    font-size: 1.5em;
    line-height: 1.25;
}

h5,
.h5 {
    font-size: 1.25em;
    line-height: 1.25;
}

h6,
.h6 {
    font-size: 0.875em;
    line-height: 1.25;
}

.display-1 {
    font-size: 5.5em;
    line-height: 1.25;
}

.display-2 {
    font-size: 4.75em;
    line-height: 1.25;
}

.display-3 {
    font-size: 4em;
    line-height: 1.25;
}

.display-4 {
    font-size: 3.25em;
    line-height: 1.25;
}

p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark-3);
    margin: 0px;
}

.text-small {
    font-size: 0.875em;
    line-height: 1.5;
}

.text-lg {
    font-size: 1.15em;
    line-height: 1.5;
}

.bg_cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@media (max-width: 767px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid transparent;
}

.btn:hover {
    color: inherit;
}

.btn:focus {
    box-shadow: none;
    outline: none;
}

.btn.btn-lg {
    font-size: 1.15em;
    padding: 16px 24px;
}

.btn.btn-sm {
    padding: 8px 16px;
}

.btn.square {
    border-radius: 0px;
}

.btn.semi-rounded {
    border-radius: 12px;
}

.btn.rounded-full {
    border-radius: 50px;
}

.btn.icon-left span,
.btn.icon-left i {
    margin-right: 8px;
}

.btn.icon-right span,
.btn.icon-right i {
    margin-left: 8px;
}

.btn.icon-btn {
    width: 48px;
    height: 48px;
    padding: 0;
    line-height: 48px;
}

.btn.icon-btn.btn-lg {
    width: 56px;
    height: 56px;
    line-height: 56px;
}

.btn.icon-btn.btn-sm {
    width: 40px;
    height: 40px;
    line-height: 40px;
}


/* ===== Buttons Css ===== */
.primary-btn {
    background: var(--primary);
    color: var(--white);
    box-shadow: var(--shadow-2);
}

.active.primary-btn,
.primary-btn:hover,
.primary-btn:focus {
    background: var(--primary-dark);
    color: var(--white);
    box-shadow: var(--shadow-4);
}

.deactive.primary-btn {
    background: var(--gray-4);
    color: var(--dark-3);
    pointer-events: none;
}

.primary-btn-outline {
    border-color: var(--primary);
    color: var(--primary);
}

.active.primary-btn-outline,
.primary-btn-outline:hover,
.primary-btn-outline:focus {
    background: var(--primary-dark);
    color: var(--white);
}

.deactive.primary-btn-outline {
    color: var(--dark-3);
    border-color: var(--gray-4);
    pointer-events: none;
}


/* One Click Scrool Top Button*/
.scroll-top {
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    color: #fff !important;
    border-radius: 0;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    border-radius: 5px;
}

.scroll-top:hover {
    -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
    box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    background-color: var(--dark-1);
}

/*===========================
    Section Title Five CSS
  ===========================*/
.section-title-five {
    text-align: center;
    max-width: 550px;
    margin: auto;
    margin-bottom: 50px;
    position: relative;
    z-index: 5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title-five {
        margin-bottom: 45px;
    }
}

@media (max-width: 767px) {
    .section-title-five {
        margin-bottom: 35px;
    }
}

.section-title-five h6 {
    font-weight: 600;
    display: inline-block;
    margin-bottom: 15px;
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 30px;
    padding: 8px 30px;
}

.section-title-five h2 {
    margin-bottom: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title-five h2 {
        font-size: 2rem;
        line-height: 2.8rem;
    }
}

@media (max-width: 767px) {
    .section-title-five h2 {
        font-size: 1.5rem;
        line-height: 1.9rem;
    }
}

.section-title-five p {
    color: var(--dark-3);
}

.made-in-ayroui {
    position: fixed;
    left: 5px;
    bottom: 5px;
    z-index: 999;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.made-in-ayroui:hover {
    transform: translateY(-5px);
}


/*===========================
    NAVBAR css 
  ===========================*/
.navbar-toggler:focus {
    box-shadow: none;
}

.mb-100 {
    margin-bottom: 100px;
}

/*===== NAVBAR NINE =====*/
.navbar-area.navbar-nine {
    background: var(--primary);
    padding: 10px 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
}

.sticky {
    position: fixed !important;
    z-index: 99 !important;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    top: 0;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine {
        padding: 10px 0;
    }
}

.navbar-area.navbar-nine .navbar-brand {
    margin: 0;
}

.navbar-area.navbar-nine .navbar {
    position: relative;
    padding: 0;
}

.navbar-area.navbar-nine .navbar .navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: var(--white);
    margin: 5px 0;
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-area.navbar-nine .navbar .navbar-toggler.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
}

.navbar-area.navbar-nine .navbar .navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
}

.navbar-area.navbar-nine .navbar .navbar-toggler.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-collapse {
        position: absolute;
        top: 116%;
        left: 0;
        width: 100%;
        background-color: var(--primary);
        z-index: 8;
        padding: 10px 16px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 1400px) {
    .navbar-area.navbar-nine .navbar .navbar-nav {
        margin-left: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav {
        margin-right: 0;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item {
    position: relative;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item a {
    display: flex;
    align-items: center;
    padding: 11px 16px;
    color: var(--white);
    text-transform: capitalize;
    position: relative;
    border-radius: 5px;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin: 14px 0;
    opacity: 0.7;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item a:hover {
    opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item a {
        padding: 10px 0;
        display: block;
        border: 0;
        margin: 0;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item a.active {
    opacity: 1;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item a i {
    font-size: 12px;
    font-weight: 700;
    padding-left: 7px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item a i {
        position: relative;
        top: -5px;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu {
    position: absolute;
    left: 0;
    top: 130%;
    width: 230px;
    background-color: var(--white);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: 99;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu {
        position: relative !important;
        width: 100% !important;
        left: 0 !important;
        top: auto !important;
        opacity: 1 !important;
        visibility: visible !important;
        right: auto;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transition: all none ease-out 0s;
        -moz-transition: all none ease-out 0s;
        -ms-transition: all none ease-out 0s;
        -o-transition: all none ease-out 0s;
        transition: all none ease-out 0s;
        box-shadow: none;
        text-align: left;
        border-top: 0;
        height: 0;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    height: auto;
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
        height: 0;
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu.show {
        height: auto;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li {
    position: relative;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li .sub-nav-toggler {
    color: var(--black);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    position: relative;
    color: var(--dark-2);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 0;
    margin: 0 0;
    z-index: 5;
    opacity: 1;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li a i {
    font-weight: 700;
    font-size: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li a i {
        display: none;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li a .sub-nav-toggler i {
    display: inline-block;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
    right: auto;
    left: 100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 1400px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
        margin-left: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
        padding-left: 30px;
    }
}

@media (max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li .sub-menu {
        padding-left: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li .sub-menu.show {
        visibility: visible;
        height: auto;
        position: relative;
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover .sub-nav-toggler {
    color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover .sub-nav-toggler {
        color: var(--primary);
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover>a {
    color: var(--primary);
    padding-left: 22px;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover>a i {
    color: var(--primary);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover>a {
        color: var(--primary);
    }
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover>a::after {
    opacity: 1;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-menu li:hover>a::before {
    opacity: 1;
}

.navbar-area.navbar-nine .navbar .navbar-nav .nav-item:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 115%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-nav .nav-item .sub-nav-toggler {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 14px;
        font-size: 16px;
        background: none;
        border: 0;
        color: var(--white);
    }
}

.navbar-area.navbar-nine .navbar .navbar-btn {
    margin-top: 6px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-area.navbar-nine .navbar .navbar-btn {
        position: absolute;
        right: 70px;
        top: 7px;
    }
}

@media (max-width: 767px) {
    .navbar-area.navbar-nine .navbar .navbar-btn {
        position: absolute;
        right: 60px;
        top: 7px;
    }
}

.navbar-area.navbar-nine .navbar .navbar-btn .menu-bar {
    font-size: 22px;
    position: relative;
    overflow: hidden;
    color: var(--white);
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid rgba(238, 238, 238, 0.425);
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-area.navbar-nine .navbar .navbar-btn .menu-bar:hover {
    border-color: transparent;
    color: var(--primary);
    background-color: var(--white);
}

/*===== SIDEBAR ONE =====*/
.sidebar-left {
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--white);
    height: 100%;
    width: 350px;
    padding-top: 80px;
    z-index: 999;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
    text-align: left;
}

.sidebar-left.open {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

@media (max-width: 767px) {
    .sidebar-left {
        width: 250px;
    }
}

.sidebar-left .sidebar-close {
    position: absolute;
    top: 30px;
    right: 30px;
}

.sidebar-left .sidebar-close .close {
    font-size: 18px;
    color: var(--black);
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -ms-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}

.sidebar-left .sidebar-close .close:hover {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar-left .sidebar-content {
    padding: 0px 30px;
}

.sidebar-left .sidebar-content .sidebar-menu {
    margin-top: 30px;
}

.sidebar-left .sidebar-content .sidebar-menu .menu-title {
    font-size: 18px;
    font-weight: 600;
}

.sidebar-left .sidebar-content .sidebar-menu ul {
    margin-top: 15px;
}

.sidebar-left .sidebar-content .sidebar-menu ul li a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 8px 0;
    color: var(--dark-3);
    text-transform: capitalize;
    position: relative;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    display: block;
}

.sidebar-left .sidebar-content .sidebar-menu ul li a:hover {
    color: var(--primary);
    padding-left: 5px;
}

.sidebar-left .sidebar-content .text {
    margin-top: 20px;
}

.sidebar-left .sidebar-content .sidebar-social {
    margin-top: 30px;
}

.sidebar-left .sidebar-content .sidebar-social .social-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
}

.sidebar-left .sidebar-content .sidebar-social ul li {
    display: inline-block;
    margin-right: 5px;
}

.sidebar-left .sidebar-content .sidebar-social ul li:last-child {
    margin: 0;
}

.sidebar-left .sidebar-content .sidebar-social ul li a {
    height: 38px;
    width: 38px;
    line-height: 38px;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 50%;
    font-size: 18px;
    color: #666;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sidebar-left .sidebar-content .sidebar-social ul li a:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: transparent;
}

.overlay-left {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 99;
}

.overlay-left.open {
    display: block;
}


/* ===== Buttons Css ===== */
.header-eight .primary-btn {
    background: var(--primary);
    color: var(--white);
    box-shadow: var(--shadow-2);
}

.header-eight .active.primary-btn,
.header-eight .primary-btn:hover,
.header-eight .primary-btn:focus {
    background: var(--primary-dark);
    color: var(--white);
    box-shadow: var(--shadow-4);
}

.header-eight .deactive.primary-btn {
    background: var(--gray-4);
    color: var(--dark-3);
    pointer-events: none;
}

/*======================================
      header Area CSS
  ========================================*/
.header-eight {
    position: relative;
    padding: 160px 0 100px 0;
    background: var(--primary);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-eight {
        padding: 130px 0 80px 0;
    }
}

@media (max-width: 767px) {
    .header-eight {
        padding: 100px 0 60px 0;
    }
}

.header-eight .header-image img {
    width: 100%;
    border-radius: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .header-eight .header-image {
        margin-top: 40px;
    }
}

.header-eight .header-content {
    border-radius: 0;
    position: relative;
    z-index: 1;
    text-align: left;
}

.header-eight .header-content h1 {
    font-weight: 700;
    color: var(--white);
    text-shadow: 0px 3px 8px #00000017;
    text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-eight .header-content h1 {
        font-size: 35px;
        line-height: 45px;
    }
}

@media (max-width: 767px) {
    .header-eight .header-content h1 {
        font-size: 30px;
        line-height: 42px;
    }
}

.header-eight .header-content h1 span {
    display: block;
}

.header-eight .header-content p {
    margin-top: 30px;
    color: var(--white);
    opacity: 0.7;
}

.header-eight .button {
    margin-top: 40px;
}

.header-eight .primary-btn {
    margin-right: 12px;
    background-color: var(--white);
    color: var(--primary);
    border: 1px solid transparent;
}

.header-eight .primary-btn:hover {
    background-color: transparent;
    color: var(--white);
    border-color: var(--white);
}

.header-eight .video-button {
    display: inline-flex;
    align-items: center;
}

@media (max-width: 767px) {
    .header-eight .video-button {
        margin-top: 20px;
    }
}

.header-eight .video-button .text {
    display: inline-block;
    margin-left: 15px;
    color: var(--white);
    font-weight: 600;
}

.header-eight .video-button .icon-btn {
    background: var(--white);
    color: var(--primary);
}

/*===========================
    about-05 css
  ===========================*/
.about-five {
    background-color: var(--light-3);
    padding-top: 120px;
    padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-five {
        padding-top: 100px;
        padding-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .about-five {
        padding-top: 80px;
        padding-bottom: 60px;
    }
}

.about-five-content {
    padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-five-content {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .about-five-content {
        padding-left: 0;
    }
}

.about-five-content .small-title {
    position: relative;
    padding-left: 30px;
}

.about-five-content .small-title::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    background-color: var(--primary);
    height: 2px;
    width: 20px;
    margin-top: -1px;
}

.about-five-content .main-title {
    margin-top: 20px;
}

.about-five-content .about-five-tab {
    margin-top: 40px;
}

.about-five-content .about-five-tab nav {
    border: none;
    background-color: var(--light-1);
    padding: 15px;
    border-radius: 5px;
}

.about-five-content .about-five-tab nav .nav-tabs {
    border: none;
}

.about-five-content .about-five-tab nav button {
    border: none;
    color: var(--dark-1);
    font-weight: 600;
    padding: 0;
    margin-right: 20px;
    position: relative;
    background-color: var(--white);
    padding: 10px 18px;
    border-radius: 4px;
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .about-five-content .about-five-tab nav button {
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .about-five-content .about-five-tab nav button:last-child {
        margin: 0;
    }
}

.about-five-content .about-five-tab nav button:hover {
    color: var(--primary);
}

.about-five-content .about-five-tab nav button.active {
    background-color: var(--primary);
    color: var(--white);
}

.about-five-content .about-five-tab nav button:last-child {
    margin-right: 0;
}

.about-five-content .about-five-tab .tab-content {
    border: none;
    padding-top: 30px;
}

.about-five-content .about-five-tab .tab-content p {
    margin-bottom: 20px;
}

.about-five-content .about-five-tab .tab-content p:last-child {
    margin: 0;
}

.about-image-five {
    padding-left: 60px;
    position: relative;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-five {
        margin-bottom: 70px;
        padding-left: 30px;
    }
}

@media (max-width: 767px) {
    .about-image-five {
        margin-bottom: 60px;
        padding-left: 0;
    }
}

.about-image-five .shape {
    position: absolute;
    left: 30px;
    top: -30px;
    z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-five .shape {
        left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-five::before {
        right: -15px;
        bottom: -15px;
    }
}

@media (max-width: 767px) {
    .about-image-five::before {
        display: none;
    }
}

.about-image-five img {
    width: 100%;
    z-index: 2;
}


/*===========================
    services css 
  ===========================*/
.services-eight {
    padding: 100px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .services-eight {
        padding: 80px 0 50px 0;
    }
}

@media (max-width: 767px) {
    .services-eight {
        padding: 60px 0 30px 0;
    }
}

.services-eight .single-services {
    padding: 40px 30px;
    border: 1px solid var(--light-1);
    border-radius: 10px;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.services-eight .single-services:hover {
    box-shadow: var(--shadow-4);
}

.services-eight .single-services:hover .service-icon {
    color: var(--white);
    border-color: transparent;
    background: var(--primary);
}

.services-eight .single-services:hover .service-icon::after {
    opacity: 1;
    visibility: visible;
}

.services-eight .single-services .service-icon {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    margin-bottom: 25px;
    background: var(--white);
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 40px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
}

.services-eight .single-services .service-icon::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background: var(--primary);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: -1;
    border-radius: 50%;
    border: 1px solid transparent;
}

.services-eight .single-services .service-content h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.services-eight .single-services .service-content p {
    color: var(--dark-3);
}



/*===== VIDEO ONE =====*/
.video-one {
    background-color: var(--light-3);
    padding: 100px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-one {
        padding: 80px;
    }
}

@media (max-width: 767px) {
    .video-one {
        padding: 60px 0;
    }
}

.video-one .video-title h5 {
    font-weight: 600;
    color: var(--primary);
}

.video-one .video-title h2 {
    font-weight: 700;
    color: var(--black);
    margin-top: 10px;
}

.video-one .video-title .text-lg {
    margin-top: 24px;
    color: var(--dark-3);
}

.video-one .video-content {
    position: relative;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.video-one .video-content img {
    border-radius: 8px;
}

.video-one .video-content a {
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--primary);
    color: var(--white);
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding-left: 3px;
}

@media (max-width: 767px) {
    .video-one .video-content a {
        width: 68px;
        height: 68px;
        line-height: 68px;
        font-size: 20px;
    }
}

.video-one .video-content a:hover {
    background-color: var(--white);
    color: var(--primary);
}


/*===== PRICING THIRTEEN =====*/
.pricing-fourteen {
    padding: 100px 0;
    background-color: var(--light-3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pricing-fourteen {
        padding: 80px;
    }
}

@media (max-width: 767px) {
    .pricing-fourteen {
        padding: 60px 0;
    }
}

.pricing-style-fourteen {
    border: 1px solid var(--light-1);
    border-radius: 10px;
    margin-top: 30px;
    background-color: var(--white);
    transition: all 0.4s ease;
    padding: 50px 35px;
    text-align: center;
    z-index: 0;
}

.pricing-style-fourteen:hover {
    box-shadow: var(--shadow-4);
}

.pricing-style-fourteen.middle {
    box-shadow: var(--shadow-4);
}

.pricing-style-fourteen.middle .title {
    border-color: var(--primary);
    background: var(--primary);
    color: var(--white);
}

.pricing-style-fourteen .title {
    font-weight: 500;
    margin-bottom: 25px;
    color: var(--primary);
    padding: 8px 20px;
    border: 2px solid var(--primary);
    display: inline-block;
    border-radius: 30px;
    font-size: 16px;
}

.pricing-style-fourteen .table-head p {
    color: var(--dark-3);
}

.pricing-style-fourteen .price {
    padding-top: 30px;
}

.pricing-style-fourteen .amount {
    font-weight: 600;
    display: inline-block;
    position: relative;
    padding-left: 15px;
    font-size: 55px;
}

.pricing-style-fourteen .currency {
    font-weight: 400;
    color: var(--dark-3);
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 6px;
}

.pricing-style-fourteen .duration {
    display: inline-block;
    font-size: 18px;
    color: var(--dark-3);
    font-weight: 400;
}

.pricing-style-fourteen .light-rounded-buttons {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 40px;
}

.pricing-style-fourteen .table-list li {
    position: relative;
    margin-bottom: 10px;
    color: var(--dark-3);
    text-align: left;
}

.pricing-style-fourteen .table-list li:last-child {
    margin: 0;
}

.pricing-style-fourteen .table-list li i {
    color: var(--primary);
    font-size: 16px;
    padding-right: 8px;
}

.pricing-style-fourteen .table-list li i.deactive {
    color: var(--dark-3);
}




/* ===== Buttons Css ===== */
.call-action .inner-content .light-rounded-buttons .primary-btn-outline {
    border-color: var(--primary);
    color: var(--primary);
}

.call-action .inner-content .light-rounded-buttons .active.primary-btn-outline,
.call-action .inner-content .light-rounded-buttons .primary-btn-outline:hover,
.call-action .inner-content .light-rounded-buttons .primary-btn-outline:focus {
    background: var(--white);
    color: var(--primary);
    border-color: transparent;
}

.call-action .inner-content .light-rounded-buttons .deactive.primary-btn-outline {
    color: var(--dark-3);
    border-color: var(--gray-4);
    pointer-events: none;
}

/*===== call action four =====*/
.call-action {
    z-index: 2;
    padding: 100px 0;
    background: linear-gradient(45deg, var(--primary), var(--primary-dark));
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .call-action {
        padding: 80px;
    }
}

@media (max-width: 767px) {
    .call-action {
        padding: 60px 0;
    }
}

.call-action:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    /* background-image: url("overlay.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: -1;
}

.call-action .inner-content {
    text-align: center;
}

.call-action .inner-content h2 {
    font-weight: 700;
    margin-bottom: 30px;
    color: var(--white);
}

.call-action .inner-content p {
    color: var(--white);
}

.call-action .inner-content .light-rounded-buttons {
    margin-top: 45px;
    display: block;
}

.call-action .inner-content .light-rounded-buttons .primary-btn-outline {
    border-color: var(--white);
    color: var(--white);
}



/*===== latest-news-area =====*/
.latest-news-area {
    background: var(--white);
    padding: 100px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-news-area {
        padding: 80px;
    }
}

@media (max-width: 767px) {
    .latest-news-area {
        padding: 60px 0;
    }
}

.latest-news-area .single-news {
    margin-top: 30px;
}

.latest-news-area .single-news .image {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.latest-news-area .single-news .image img {
    height: 100%;
    width: 100%;
    transition: all 0.4s ease;
}

.latest-news-area .single-news .image .meta-details {
    display: inline-block;
    padding: 6px 15px 6px 7px;
    border-radius: 30px;
    background-color: var(--primary);
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.latest-news-area .single-news .image .meta-details img {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: inline-block;
}

.latest-news-area .single-news .image .meta-details span {
    color: var(--white);
    display: inline-block;
    margin-left: 10px;
    font-size: 10px;
    font-weight: 500;
}

.latest-news-area .single-news .content-body .title {
    margin: 30px 0 20px 0;
}

.latest-news-area .single-news .content-body .title a {
    color: var(--black);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.latest-news-area .single-news .content-body .title a:hover {
    color: var(--primary);
}

.latest-news-area .single-news .content-body p {
    color: var(--dark-3);
}

.latest-news-area .single-news:hover .image .thumb {
    transform: scale(1.1) rotate(1deg);
}


/*======================================
      Brand CSS
  ========================================*/
.brand-area {
    padding: 100px 0;
    background: var(--light-3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-area {
        padding: 80px;
    }
}

@media (max-width: 767px) {
    .brand-area {
        padding: 60px 0;
    }
}

.brand-area .clients-logos {
    text-align: center;
    display: inline-block;
    margin-top: 20px;
}

.brand-area .clients-logos .single-image {
    display: inline-block;
    margin: 13px 10px;
    background-color: var(--white);
    line-height: 100px;
    padding: 8px 25px;
    border-radius: 8px;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    border: 1px solid #eee;
}

.brand-area .clients-logos .single-image:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.096);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
}

.brand-area .clients-logos img {
    max-width: 170px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

/* ===== Buttons Css ===== */
.contact-form-wrapper .contact-form .primary-btn {
    background: var(--primary);
    color: var(--white);
    box-shadow: var(--shadow-2);
}

.contact-form-wrapper .contact-form .active.primary-btn,
.contact-form-wrapper .contact-form .primary-btn:hover,
.contact-form-wrapper .contact-form .primary-btn:focus {
    background: var(--primary-dark);
    color: var(--white);
    box-shadow: var(--shadow-4);
}

.contact-form-wrapper .contact-form .deactive.primary-btn {
    background: var(--gray-4);
    color: var(--dark-3);
    pointer-events: none;
}

/*======================================
      Contact CSS
  ========================================*/
.contact-section {
    position: relative;
    z-index: 3;
    padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-section {
        padding-top: 80px;
    }
}

@media (max-width: 767px) {
    .contact-section {
        padding-top: 60px;
    }
}

.contact-section .contact-item-wrapper .contact-item {
    display: flex;
    border: 1px solid var(--gray-4);
    border-radius: 10px;
    background: var(--white);
    margin-bottom: 30px;
    padding: 20px 30px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.contact-section .contact-item-wrapper .contact-item:hover {
    box-shadow: var(--shadow-4);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .contact-section .contact-item-wrapper .contact-item {
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-section .contact-item-wrapper .contact-item {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .contact-section .contact-item-wrapper .contact-item {
        flex-direction: column;
    }
}

.contact-section .contact-item-wrapper .contact-item .contact-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary);
    color: var(--white);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.contact-section .contact-item-wrapper .contact-item .contact-content {
    margin-left: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .contact-section .contact-item-wrapper .contact-item .contact-content {
        margin-left: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-section .contact-item-wrapper .contact-item .contact-content {
        margin-left: 0px;
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .contact-section .contact-item-wrapper .contact-item .contact-content {
        margin-left: 0px;
        margin-top: 20px;
    }
}

.contact-section .contact-item-wrapper .contact-item .contact-content h4 {
    font-size: 20px;
    color: var(--primary);
    margin-bottom: 10px;
}

.contact-form-wrapper {
    padding: 50px 40px;
    background: var(--white);
    border: 1px solid var(--gray-4);
    margin-left: 0px;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.contact-form-wrapper:hover {
    box-shadow: var(--shadow-4);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .contact-form-wrapper {
        margin-left: 30px;
    }
}

.contact-form-wrapper .section-title {
    margin-bottom: 30px;
}

.contact-form-wrapper .section-title span {
    font-size: 20px;
    color: var(--primary);
    font-weight: 700;
}

.contact-form-wrapper .section-title h2 {
    margin-bottom: 10px;
}

.contact-form-wrapper .section-title p {
    color: var(--dark-3);
}

.contact-form-wrapper .contact-form input,
.contact-form-wrapper .contact-form textarea {
    padding: 15px 25px;
    border-radius: 30px;
    border: 1px solid var(--gray-4);
    margin-bottom: 25px;
    width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {

    .contact-form-wrapper .contact-form input,
    .contact-form-wrapper .contact-form textarea {
        padding: 12px 25px;
    }
}

.contact-form-wrapper .contact-form input:focus,
.contact-form-wrapper .contact-form textarea:focus {
    border-color: var(--primary);
}

.contact-form-wrapper .contact-form textarea {
    border-radius: 18px;
}

.map-style-9 {
    margin-top: -130px;
}


/* Footer eleven css */
.footer-eleven {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: var(--white);
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .footer-eleven {
        padding-top: 30px;
        padding-bottom: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-eleven .footer-widget {
        margin-top: 40px;
    }
}

@media (max-width: 767px) {
    .footer-eleven .footer-widget {
        margin-top: 40px;
        text-align: center;
    }
}

.footer-eleven .footer-widget h5 {
    font-weight: 700;
    margin-bottom: 35px;
    color: var(--black);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
    .footer-eleven .footer-widget h5 {
        margin-bottom: 25px;
    }
}

.footer-eleven .f-about {
    padding-right: 30px;
}

@media (max-width: 767px) {
    .footer-eleven .f-about {
        padding: 0;
    }
}

.footer-eleven .f-about p {
    color: var(--dark-3);
    margin-top: 20px;
}

.footer-eleven .f-about .copyright-text {
    color: var(--dark-3);
    margin-top: 40px;
}

.footer-eleven .f-about .copyright-text span {
    display: block;
}

@media (max-width: 767px) {
    .footer-eleven .f-about .copyright-text {
        margin-top: 20px;
    }
}

.footer-eleven .f-about .copyright-text a {
    color: var(--primary);
}

.footer-eleven .f-about .copyright-text a:hover {
    color: var(--primary-dark);
}

.footer-eleven .f-link li {
    display: block;
    margin-bottom: 12px;
}

.footer-eleven .f-link li:last-child {
    margin: 0;
}

.footer-eleven .f-link li a {
    color: var(--dark-3);
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.footer-eleven .f-link li a:hover {
    color: var(--primary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 1400px) {
    .footer-eleven .newsletter {
        padding-left: 80px;
    }
}

.footer-eleven .newsletter p {
    color: var(--dark-3);
}

.footer-eleven .newsletter-form {
    margin-top: 30px;
    position: relative;
}

.footer-eleven .newsletter-form input {
    height: 55px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--gray-4);
    box-shadow: none;
    text-shadow: none;
    padding-left: 18px;
    padding-right: 65px;
    transition: all 0.4s ease;
}

.footer-eleven .newsletter-form input:focus {
    border-color: var(--primary);
}

.footer-eleven .newsletter-form .button {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
}

.footer-eleven .newsletter-form .sub-btn {
    height: 42px;
    width: 42px;
    border-radius: 6px;
    background-color: var(--primary);
    color: var(--white);
    text-align: center;
    line-height: 42px;
    border: none;
    box-shadow: none;
    text-shadow: none;
    font-size: 17px;
    transition: all 0.4s ease;
}

.footer-eleven .newsletter-form .sub-btn:hover {
    color: var(--white);
    background-color: var(--primary-dark);
}
</style>
  
<style> /*--------------------------------
  
  LineIcons Web Font
  Author: lineicons.com
  
  -------------------------------- */
 @font-face {
     font-family: "LineIcons";
     src: url("../../public/LineIcons.eot");
     src: url("../../public/LineIcons.eot") format("embedded-opentype"),
         url("../../public/LineIcons.woff2") format("woff2"),
         url("../../public/LineIcons.woff") format("woff"),
         url("../../public/LineIcons.ttf") format("truetype"),
         url("../../public/LineIcons.svg") format("svg");
     font-weight: normal;
     font-style: normal;
 }

 /*------------------------
      base class definition
  -------------------------*/
 .lni {
     display: inline-block;
     font: normal normal normal 1em/1 "LineIcons";
     color: inherit;
     flex-shrink: 0;
     speak: none;
     text-transform: none;
     line-height: 1;
     vertical-align: -0.125em;
     /* Better Font Rendering */
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
 }

 /*------------------------
    change icon size
  -------------------------*/
 /* relative units */
 .lni-sm {
     font-size: 0.8em;
 }

 .lni-lg {
     font-size: 1.2em;
 }

 /* absolute units */
 .lni-16 {
     font-size: 16px;
 }

 .lni-32 {
     font-size: 32px;
 }

 /*------------------------
    spinning icons
  -------------------------*/
 .lni-is-spinning {
     animation: lni-spin 1s infinite linear;
 }

 @keyframes lni-spin {
     0% {
         transform: rotate(0deg);
     }

     100% {
         transform: rotate(360deg);
     }
 }

 /*------------------------
    rotated/flipped icons
  -------------------------*/
 .lni-rotate-90 {
     transform: rotate(90deg);
 }

 .lni-rotate-180 {
     transform: rotate(180deg);
 }

 .lni-rotate-270 {
     transform: rotate(270deg);
 }

 .lni-flip-y {
     transform: scaleY(-1);
 }

 .lni-flip-x {
     transform: scaleX(-1);
 }

 /*------------------------
      icons
  -------------------------*/

 .lni-500px::before {
     content: "\ea03";
 }

 .lni-add-files::before {
     content: "\ea01";
 }

 .lni-adobe::before {
     content: "\ea06";
 }

 .lni-agenda::before {
     content: "\ea02";
 }

 .lni-airbnb::before {
     content: "\ea07";
 }

 .lni-alarm-clock::before {
     content: "\ea08";
 }

 .lni-alarm::before {
     content: "\ea04";
 }

 .lni-amazon-original::before {
     content: "\ea05";
 }

 .lni-amazon-pay::before {
     content: "\ea09";
 }

 .lni-amazon::before {
     content: "\ea0a";
 }

 .lni-ambulance::before {
     content: "\ea0b";
 }

 .lni-amex::before {
     content: "\ea0c";
 }

 .lni-anchor::before {
     content: "\ea0d";
 }

 .lni-android-original::before {
     content: "\ea0e";
 }

 .lni-android::before {
     content: "\ea0f";
 }

 .lni-angellist::before {
     content: "\ea10";
 }

 .lni-angle-double-down::before {
     content: "\ea11";
 }

 .lni-angle-double-left::before {
     content: "\ea12";
 }

 .lni-angle-double-right::before {
     content: "\ea13";
 }

 .lni-angle-double-up::before {
     content: "\ea14";
 }

 .lni-angular::before {
     content: "\ea15";
 }

 .lni-apartment::before {
     content: "\ea16";
 }

 .lni-app-store::before {
     content: "\ea17";
 }

 .lni-apple-music::before {
     content: "\ea18";
 }

 .lni-apple-pay::before {
     content: "\ea19";
 }

 .lni-apple::before {
     content: "\ea1a";
 }

 .lni-archive::before {
     content: "\ea1f";
 }

 .lni-arrow-down-circle::before {
     content: "\ea1b";
 }

 .lni-arrow-down::before {
     content: "\ea1c";
 }

 .lni-arrow-left-circle::before {
     content: "\ea1d";
 }

 .lni-arrow-left::before {
     content: "\ea1e";
 }

 .lni-arrow-right-circle::before {
     content: "\ea20";
 }

 .lni-arrow-right::before {
     content: "\ea21";
 }

 .lni-arrow-top-left::before {
     content: "\ea22";
 }

 .lni-arrow-top-right::before {
     content: "\ea23";
 }

 .lni-arrow-up-circle::before {
     content: "\ea24";
 }

 .lni-arrow-up::before {
     content: "\ea25";
 }

 .lni-arrows-horizontal::before {
     content: "\ea26";
 }

 .lni-arrows-vertical::before {
     content: "\ea27";
 }

 .lni-atlassian::before {
     content: "\ea28";
 }

 .lni-aws::before {
     content: "\ea29";
 }

 .lni-azure::before {
     content: "\ea2a";
 }

 .lni-backward::before {
     content: "\ea2b";
 }

 .lni-baloon::before {
     content: "\ea2c";
 }

 .lni-ban::before {
     content: "\ea2d";
 }

 .lni-bar-chart::before {
     content: "\ea2e";
 }

 .lni-basketball::before {
     content: "\ea2f";
 }

 .lni-behance-original::before {
     content: "\ea30";
 }

 .lni-behance::before {
     content: "\ea31";
 }

 .lni-bi-cycle::before {
     content: "\ea32";
 }

 .lni-bitbucket::before {
     content: "\ea33";
 }

 .lni-bitcoin::before {
     content: "\ea34";
 }

 .lni-blackboard::before {
     content: "\ea35";
 }

 .lni-blogger::before {
     content: "\ea36";
 }

 .lni-bluetooth-original::before {
     content: "\ea37";
 }

 .lni-bluetooth::before {
     content: "\ea38";
 }

 .lni-bold::before {
     content: "\ea39";
 }

 .lni-bolt-alt::before {
     content: "\ea3a";
 }

 .lni-bolt::before {
     content: "\ea40";
 }

 .lni-book::before {
     content: "\ea3b";
 }

 .lni-bookmark-alt::before {
     content: "\ea3c";
 }

 .lni-bookmark::before {
     content: "\ea3d";
 }

 .lni-bootstrap::before {
     content: "\ea3e";
 }

 .lni-bricks::before {
     content: "\ea3f";
 }

 .lni-bridge::before {
     content: "\ea41";
 }

 .lni-briefcase::before {
     content: "\ea42";
 }

 .lni-brush-alt::before {
     content: "\ea43";
 }

 .lni-brush::before {
     content: "\ea44";
 }

 .lni-btc::before {
     content: "\ea45";
 }

 .lni-bubble::before {
     content: "\ea46";
 }

 .lni-bug::before {
     content: "\ea47";
 }

 .lni-bulb::before {
     content: "\ea48";
 }

 .lni-bullhorn::before {
     content: "\ea49";
 }

 .lni-burger::before {
     content: "\ea4a";
 }

 .lni-bus::before {
     content: "\ea4b";
 }

 .lni-cake::before {
     content: "\ea4c";
 }

 .lni-calculator::before {
     content: "\ea4d";
 }

 .lni-calendar::before {
     content: "\ea4e";
 }

 .lni-camera::before {
     content: "\ea4f";
 }

 .lni-candy-cane::before {
     content: "\ea50";
 }

 .lni-candy::before {
     content: "\ea51";
 }

 .lni-capsule::before {
     content: "\ea52";
 }

 .lni-car-alt::before {
     content: "\ea53";
 }

 .lni-car::before {
     content: "\ea54";
 }

 .lni-caravan::before {
     content: "\ea55";
 }

 .lni-cart-full::before {
     content: "\ea56";
 }

 .lni-cart::before {
     content: "\ea57";
 }

 .lni-certificate::before {
     content: "\ea58";
 }

 .lni-check-box::before {
     content: "\ea59";
 }

 .lni-checkmark-circle::before {
     content: "\ea5a";
 }

 .lni-checkmark::before {
     content: "\ea5b";
 }

 .lni-chef-hat::before {
     content: "\ea5c";
 }

 .lni-chevron-down-circle::before {
     content: "\ea5d";
 }

 .lni-chevron-down::before {
     content: "\ea5e";
 }

 .lni-chevron-left-circle::before {
     content: "\ea5f";
 }

 .lni-chevron-left::before {
     content: "\ea60";
 }

 .lni-chevron-right-circle::before {
     content: "\ea61";
 }

 .lni-chevron-right::before {
     content: "\ea62";
 }

 .lni-chevron-up-circle::before {
     content: "\ea63";
 }

 .lni-chevron-up::before {
     content: "\ea64";
 }

 .lni-chrome::before {
     content: "\ea65";
 }

 .lni-chromecast::before {
     content: "\ea66";
 }

 .lni-circle-minus::before {
     content: "\ea67";
 }

 .lni-circle-plus::before {
     content: "\ea68";
 }

 .lni-clipboard::before {
     content: "\ea69";
 }

 .lni-close::before {
     content: "\ea6a";
 }

 .lni-cloud-check::before {
     content: "\ea6b";
 }

 .lni-cloud-download::before {
     content: "\ea6c";
 }

 .lni-cloud-network::before {
     content: "\ea6d";
 }

 .lni-cloud-sync::before {
     content: "\ea6e";
 }

 .lni-cloud-upload::before {
     content: "\ea6f";
 }

 .lni-cloud::before {
     content: "\ea70";
 }

 .lni-cloudflare::before {
     content: "\ea71";
 }

 .lni-cloudy-sun::before {
     content: "\ea72";
 }

 .lni-code-alt::before {
     content: "\ea73";
 }

 .lni-code::before {
     content: "\ea74";
 }

 .lni-codepen::before {
     content: "\ea75";
 }

 .lni-coffee-cup::before {
     content: "\ea76";
 }

 .lni-cog::before {
     content: "\ea77";
 }

 .lni-cogs::before {
     content: "\ea78";
 }

 .lni-coin::before {
     content: "\ea79";
 }

 .lni-comments-alt::before {
     content: "\ea7a";
 }

 .lni-comments-reply::before {
     content: "\ea7b";
 }

 .lni-comments::before {
     content: "\ea7c";
 }

 .lni-compass::before {
     content: "\ea7d";
 }

 .lni-connectdevelop::before {
     content: "\ea7e";
 }

 .lni-construction-hammer::before {
     content: "\ea7f";
 }

 .lni-construction::before {
     content: "\ea80";
 }

 .lni-consulting::before {
     content: "\ea81";
 }

 .lni-control-panel::before {
     content: "\ea82";
 }

 .lni-cool::before {
     content: "\ea83";
 }

 .lni-cpanel::before {
     content: "\ea84";
 }

 .lni-creative-commons::before {
     content: "\ea85";
 }

 .lni-credit-cards::before {
     content: "\ea86";
 }

 .lni-crop::before {
     content: "\ea87";
 }

 .lni-cross-circle::before {
     content: "\ea88";
 }

 .lni-crown::before {
     content: "\ea89";
 }

 .lni-css3::before {
     content: "\ea8a";
 }

 .lni-cup::before {
     content: "\ea8b";
 }

 .lni-customer::before {
     content: "\ea8c";
 }

 .lni-cut::before {
     content: "\ea8d";
 }

 .lni-dashboard::before {
     content: "\ea8e";
 }

 .lni-database::before {
     content: "\ea8f";
 }

 .lni-delivery::before {
     content: "\ea90";
 }

 .lni-dev::before {
     content: "\ea91";
 }

 .lni-diamond-alt::before {
     content: "\ea92";
 }

 .lni-diamond::before {
     content: "\ea93";
 }

 .lni-digitalocean::before {
     content: "\ea94";
 }

 .lni-diners-club::before {
     content: "\ea95";
 }

 .lni-dinner::before {
     content: "\ea96";
 }

 .lni-direction-alt::before {
     content: "\ea97";
 }

 .lni-direction-ltr::before {
     content: "\ea98";
 }

 .lni-direction-rtl::before {
     content: "\ea99";
 }

 .lni-direction::before {
     content: "\ea9a";
 }

 .lni-discord::before {
     content: "\ea9b";
 }

 .lni-discover::before {
     content: "\ea9c";
 }

 .lni-display-alt::before {
     content: "\ea9d";
 }

 .lni-display::before {
     content: "\ea9e";
 }

 .lni-docker::before {
     content: "\ea9f";
 }

 .lni-dollar::before {
     content: "\eaa0";
 }

 .lni-domain::before {
     content: "\eaa1";
 }

 .lni-download::before {
     content: "\eaa2";
 }

 .lni-dribbble::before {
     content: "\eaa3";
 }

 .lni-drop::before {
     content: "\eaa4";
 }

 .lni-dropbox-original::before {
     content: "\eaa5";
 }

 .lni-dropbox::before {
     content: "\eaa6";
 }

 .lni-drupal-original::before {
     content: "\eaa7";
 }

 .lni-drupal::before {
     content: "\eaa8";
 }

 .lni-dumbbell::before {
     content: "\eaa9";
 }

 .lni-edge::before {
     content: "\eaaa";
 }

 .lni-empty-file::before {
     content: "\eaab";
 }

 .lni-enter::before {
     content: "\eaac";
 }

 .lni-envato::before {
     content: "\eaad";
 }

 .lni-envelope::before {
     content: "\eaae";
 }

 .lni-eraser::before {
     content: "\eaaf";
 }

 .lni-euro::before {
     content: "\eab0";
 }

 .lni-exit-down::before {
     content: "\eab1";
 }

 .lni-exit-up::before {
     content: "\eab2";
 }

 .lni-exit::before {
     content: "\eab3";
 }

 .lni-eye::before {
     content: "\eab4";
 }

 .lni-facebook-filled::before {
     content: "\eab5";
 }

 .lni-facebook-messenger::before {
     content: "\eab6";
 }

 .lni-facebook-original::before {
     content: "\eab7";
 }

 .lni-facebook-oval::before {
     content: "\eab8";
 }

 .lni-facebook::before {
     content: "\eab9";
 }

 .lni-figma::before {
     content: "\eaba";
 }

 .lni-files::before {
     content: "\eabb";
 }

 .lni-firefox-original::before {
     content: "\eabc";
 }

 .lni-firefox::before {
     content: "\eabd";
 }

 .lni-fireworks::before {
     content: "\eabe";
 }

 .lni-first-aid::before {
     content: "\eabf";
 }

 .lni-flag-alt::before {
     content: "\eac0";
 }

 .lni-flag::before {
     content: "\eac1";
 }

 .lni-flags::before {
     content: "\eac2";
 }

 .lni-flickr::before {
     content: "\eac3";
 }

 .lni-flower::before {
     content: "\eac4";
 }

 .lni-folder::before {
     content: "\eac5";
 }

 .lni-forward::before {
     content: "\eac6";
 }

 .lni-frame-expand::before {
     content: "\eac7";
 }

 .lni-fresh-juice::before {
     content: "\eac8";
 }

 .lni-friendly::before {
     content: "\eac9";
 }

 .lni-full-screen::before {
     content: "\eaca";
 }

 .lni-funnel::before {
     content: "\eacb";
 }

 .lni-gallery::before {
     content: "\eacc";
 }

 .lni-game::before {
     content: "\eacd";
 }

 .lni-gatsby::before {
     content: "\eace";
 }

 .lni-gift::before {
     content: "\eacf";
 }

 .lni-git::before {
     content: "\ead0";
 }

 .lni-github-original::before {
     content: "\ead1";
 }

 .lni-github::before {
     content: "\ead2";
 }

 .lni-goodreads::before {
     content: "\ead3";
 }

 .lni-google-drive::before {
     content: "\ead4";
 }

 .lni-google-pay::before {
     content: "\ead5";
 }

 .lni-google-wallet::before {
     content: "\ead6";
 }

 .lni-google::before {
     content: "\ead7";
 }

 .lni-graduation::before {
     content: "\ead8";
 }

 .lni-graph::before {
     content: "\ead9";
 }

 .lni-grid-alt::before {
     content: "\eada";
 }

 .lni-grid::before {
     content: "\eadb";
 }

 .lni-grow::before {
     content: "\eadc";
 }

 .lni-hacker-news::before {
     content: "\eadd";
 }

 .lni-hammer::before {
     content: "\eade";
 }

 .lni-hand::before {
     content: "\eadf";
 }

 .lni-handshake::before {
     content: "\eae0";
 }

 .lni-happy::before {
     content: "\eae1";
 }

 .lni-harddrive::before {
     content: "\eae2";
 }

 .lni-headphone-alt::before {
     content: "\eae3";
 }

 .lni-headphone::before {
     content: "\eae4";
 }

 .lni-heart-filled::before {
     content: "\eae5";
 }

 .lni-heart-monitor::before {
     content: "\eae6";
 }

 .lni-heart::before {
     content: "\eae7";
 }

 .lni-helicopter::before {
     content: "\eae8";
 }

 .lni-helmet::before {
     content: "\eae9";
 }

 .lni-help::before {
     content: "\eaea";
 }

 .lni-highlight-alt::before {
     content: "\eaeb";
 }

 .lni-highlight::before {
     content: "\eaec";
 }

 .lni-home::before {
     content: "\eaed";
 }

 .lni-hospital::before {
     content: "\eaee";
 }

 .lni-hourglass::before {
     content: "\eaef";
 }

 .lni-html5::before {
     content: "\eaf0";
 }

 .lni-image::before {
     content: "\eaf1";
 }

 .lni-imdb::before {
     content: "\eaf2";
 }

 .lni-inbox::before {
     content: "\eaf3";
 }

 .lni-indent-decrease::before {
     content: "\eaf4";
 }

 .lni-indent-increase::before {
     content: "\eaf5";
 }

 .lni-infinite::before {
     content: "\eaf6";
 }

 .lni-information::before {
     content: "\eaf7";
 }

 .lni-instagram-filled::before {
     content: "\eaf8";
 }

 .lni-instagram-original::before {
     content: "\eaf9";
 }

 .lni-instagram::before {
     content: "\eafa";
 }

 .lni-invention::before {
     content: "\eafb";
 }

 .lni-invest-monitor::before {
     content: "\eafc";
 }

 .lni-investment::before {
     content: "\eafd";
 }

 .lni-island::before {
     content: "\eafe";
 }

 .lni-italic::before {
     content: "\eaff";
 }

 .lni-java::before {
     content: "\eb00";
 }

 .lni-javascript::before {
     content: "\eb01";
 }

 .lni-jcb::before {
     content: "\eb02";
 }

 .lni-joomla-original::before {
     content: "\eb03";
 }

 .lni-joomla::before {
     content: "\eb04";
 }

 .lni-jsfiddle::before {
     content: "\eb05";
 }

 .lni-juice::before {
     content: "\eb06";
 }

 .lni-key::before {
     content: "\eb07";
 }

 .lni-keyboard::before {
     content: "\eb08";
 }

 .lni-keyword-research::before {
     content: "\eb09";
 }

 .lni-laptop-phone::before {
     content: "\eb0a";
 }

 .lni-laptop::before {
     content: "\eb0b";
 }

 .lni-laravel::before {
     content: "\eb0c";
 }

 .lni-layers::before {
     content: "\eb0d";
 }

 .lni-layout::before {
     content: "\eb0e";
 }

 .lni-leaf::before {
     content: "\eb0f";
 }

 .lni-library::before {
     content: "\eb10";
 }

 .lni-license::before {
     content: "\eb11";
 }

 .lni-lifering::before {
     content: "\eb12";
 }

 .lni-line-dashed::before {
     content: "\eb13";
 }

 .lni-line-dotted::before {
     content: "\eb14";
 }

 .lni-line-double::before {
     content: "\eb15";
 }

 .lni-line-spacing::before {
     content: "\eb16";
 }

 .lni-line::before {
     content: "\eb17";
 }

 .lni-lineicons-alt::before {
     content: "\eb18";
 }

 .lni-lineicons::before {
     content: "\eb19";
 }

 .lni-link::before {
     content: "\eb1a";
 }

 .lni-linkedin-original::before {
     content: "\eb1b";
 }

 .lni-linkedin::before {
     content: "\eb1c";
 }

 .lni-list::before {
     content: "\eb1d";
 }

 .lni-lock-alt::before {
     content: "\eb1e";
 }

 .lni-lock::before {
     content: "\eb1f";
 }

 .lni-magento::before {
     content: "\eb20";
 }

 .lni-magnet::before {
     content: "\eb21";
 }

 .lni-magnifier::before {
     content: "\eb22";
 }

 .lni-mailchimp::before {
     content: "\eb23";
 }

 .lni-map-marker::before {
     content: "\eb24";
 }

 .lni-map::before {
     content: "\eb25";
 }

 .lni-markdown::before {
     content: "\eb26";
 }

 .lni-mashroom::before {
     content: "\eb27";
 }

 .lni-mastercard::before {
     content: "\eb28";
 }

 .lni-medium::before {
     content: "\eb29";
 }

 .lni-menu::before {
     content: "\eb2a";
 }

 .lni-mic::before {
     content: "\eb2b";
 }

 .lni-microphone::before {
     content: "\eb2c";
 }

 .lni-microscope::before {
     content: "\eb2d";
 }

 .lni-microsoft-edge::before {
     content: "\eb2e";
 }

 .lni-microsoft::before {
     content: "\eb2f";
 }

 .lni-minus::before {
     content: "\eb30";
 }

 .lni-mobile::before {
     content: "\eb31";
 }

 .lni-money-location::before {
     content: "\eb32";
 }

 .lni-money-protection::before {
     content: "\eb33";
 }

 .lni-more-alt::before {
     content: "\eb34";
 }

 .lni-more::before {
     content: "\eb35";
 }

 .lni-mouse::before {
     content: "\eb36";
 }

 .lni-move::before {
     content: "\eb37";
 }

 .lni-music::before {
     content: "\eb38";
 }

 .lni-netlify::before {
     content: "\eb39";
 }

 .lni-network::before {
     content: "\eb3a";
 }

 .lni-night::before {
     content: "\eb3b";
 }

 .lni-nodejs-alt::before {
     content: "\eb3c";
 }

 .lni-nodejs::before {
     content: "\eb3d";
 }

 .lni-notepad::before {
     content: "\eb3e";
 }

 .lni-npm::before {
     content: "\eb3f";
 }

 .lni-offer::before {
     content: "\eb40";
 }

 .lni-opera::before {
     content: "\eb41";
 }

 .lni-package::before {
     content: "\eb42";
 }

 .lni-page-break::before {
     content: "\eb43";
 }

 .lni-pagination::before {
     content: "\eb44";
 }

 .lni-paint-bucket::before {
     content: "\eb45";
 }

 .lni-paint-roller::before {
     content: "\eb46";
 }

 .lni-pallet::before {
     content: "\eb47";
 }

 .lni-paperclip::before {
     content: "\eb48";
 }

 .lni-patreon::before {
     content: "\eb49";
 }

 .lni-pause::before {
     content: "\eb4a";
 }

 .lni-paypal-original::before {
     content: "\eb4b";
 }

 .lni-paypal::before {
     content: "\eb4c";
 }

 .lni-pencil-alt::before {
     content: "\eb4d";
 }

 .lni-pencil::before {
     content: "\eb4e";
 }

 .lni-phone-set::before {
     content: "\eb4f";
 }

 .lni-phone::before {
     content: "\eb50";
 }

 .lni-php::before {
     content: "\eb51";
 }

 .lni-pie-chart::before {
     content: "\eb52";
 }

 .lni-pilcrow::before {
     content: "\eb53";
 }

 .lni-pin::before {
     content: "\eb54";
 }

 .lni-pinterest::before {
     content: "\eb55";
 }

 .lni-pizza::before {
     content: "\eb56";
 }

 .lni-plane::before {
     content: "\eb57";
 }

 .lni-play-store::before {
     content: "\eb58";
 }

 .lni-play::before {
     content: "\eb59";
 }

 .lni-playstation::before {
     content: "\eb5a";
 }

 .lni-plug::before {
     content: "\eb5b";
 }

 .lni-plus::before {
     content: "\eb5c";
 }

 .lni-pointer-down::before {
     content: "\eb5d";
 }

 .lni-pointer-left::before {
     content: "\eb5e";
 }

 .lni-pointer-right::before {
     content: "\eb5f";
 }

 .lni-pointer-top::before {
     content: "\eb60";
 }

 .lni-pointer::before {
     content: "\eb61";
 }

 .lni-popup::before {
     content: "\eb62";
 }

 .lni-postcard::before {
     content: "\eb63";
 }

 .lni-pound::before {
     content: "\eb64";
 }

 .lni-power-switch::before {
     content: "\eb65";
 }

 .lni-printer::before {
     content: "\eb66";
 }

 .lni-producthunt::before {
     content: "\eb67";
 }

 .lni-protection::before {
     content: "\eb68";
 }

 .lni-pulse::before {
     content: "\eb69";
 }

 .lni-pyramids::before {
     content: "\eb6a";
 }

 .lni-python::before {
     content: "\eb6b";
 }

 .lni-question-circle::before {
     content: "\eb6c";
 }

 .lni-quora::before {
     content: "\eb6d";
 }

 .lni-quotation::before {
     content: "\eb6e";
 }

 .lni-radio-button::before {
     content: "\eb6f";
 }

 .lni-rain::before {
     content: "\eb70";
 }

 .lni-react::before {
     content: "\eb73";
 }

 .lni-reddit::before {
     content: "\eb71";
 }

 .lni-reload::before {
     content: "\eb72";
 }

 .lni-remove-file::before {
     content: "\eb74";
 }

 .lni-reply::before {
     content: "\eb75";
 }

 .lni-restaurant::before {
     content: "\eb76";
 }

 .lni-revenue::before {
     content: "\eb77";
 }

 .lni-road::before {
     content: "\eb78";
 }

 .lni-rocket::before {
     content: "\eb79";
 }

 .lni-rss-feed::before {
     content: "\eb7a";
 }

 .lni-ruler-alt::before {
     content: "\eb7b";
 }

 .lni-ruler-pencil::before {
     content: "\eb7c";
 }

 .lni-ruler::before {
     content: "\eb7d";
 }

 .lni-rupee::before {
     content: "\eb7e";
 }

 .lni-sad::before {
     content: "\eb7f";
 }

 .lni-save::before {
     content: "\eb80";
 }

 .lni-school-bench-alt::before {
     content: "\eb81";
 }

 .lni-school-bench::before {
     content: "\eb82";
 }

 .lni-scooter::before {
     content: "\eb83";
 }

 .lni-scroll-down::before {
     content: "\eb84";
 }

 .lni-search-alt::before {
     content: "\eb85";
 }

 .lni-search::before {
     content: "\eb86";
 }

 .lni-select::before {
     content: "\eb87";
 }

 .lni-seo::before {
     content: "\eb88";
 }

 .lni-service::before {
     content: "\eb89";
 }

 .lni-share-alt-1::before {
     content: "\eb8a";
 }

 .lni-share-alt::before {
     content: "\eb8b";
 }

 .lni-share::before {
     content: "\eb8c";
 }

 .lni-shield::before {
     content: "\eb8d";
 }

 .lni-shift-left::before {
     content: "\eb8e";
 }

 .lni-shift-right::before {
     content: "\eb8f";
 }

 .lni-ship::before {
     content: "\eb90";
 }

 .lni-shopify::before {
     content: "\eb91";
 }

 .lni-shopping-basket::before {
     content: "\eb92";
 }

 .lni-shortcode::before {
     content: "\eb93";
 }

 .lni-shovel::before {
     content: "\eb94";
 }

 .lni-shuffle::before {
     content: "\eb95";
 }

 .lni-signal::before {
     content: "\eb96";
 }

 .lni-sketch::before {
     content: "\eb97";
 }

 .lni-skipping-rope::before {
     content: "\eb98";
 }

 .lni-skype::before {
     content: "\eb99";
 }

 .lni-slack-line::before {
     content: "\eb9a";
 }

 .lni-slack::before {
     content: "\eb9b";
 }

 .lni-slice::before {
     content: "\eb9c";
 }

 .lni-slideshare::before {
     content: "\eb9d";
 }

 .lni-slim::before {
     content: "\eb9e";
 }

 .lni-smile::before {
     content: "\eb9f";
 }

 .lni-snapchat::before {
     content: "\eba0";
 }

 .lni-sort-alpha-asc::before {
     content: "\eba1";
 }

 .lni-sort-amount-asc::before {
     content: "\eba2";
 }

 .lni-sort-amount-dsc::before {
     content: "\eba3";
 }

 .lni-soundcloud-original::before {
     content: "\eba4";
 }

 .lni-soundcloud::before {
     content: "\eba5";
 }

 .lni-speechless::before {
     content: "\eba6";
 }

 .lni-spellcheck::before {
     content: "\eba7";
 }

 .lni-spinner-arrow::before {
     content: "\eba8";
 }

 .lni-spinner-solid::before {
     content: "\eba9";
 }

 .lni-spinner::before {
     content: "\ebaa";
 }

 .lni-spotify-original::before {
     content: "\ebab";
 }

 .lni-spotify::before {
     content: "\ebac";
 }

 .lni-spray::before {
     content: "\ebad";
 }

 .lni-sprout::before {
     content: "\ebae";
 }

 .lni-squarespace::before {
     content: "\ebaf";
 }

 .lni-stackoverflow::before {
     content: "\ebb0";
 }

 .lni-stamp::before {
     content: "\ebb1";
 }

 .lni-star-empty::before {
     content: "\ebb2";
 }

 .lni-star-filled::before {
     content: "\ebb3";
 }

 .lni-star-half::before {
     content: "\ebb4";
 }

 .lni-star::before {
     content: "\ebb5";
 }

 .lni-stats-down::before {
     content: "\ebb6";
 }

 .lni-stats-up::before {
     content: "\ebb7";
 }

 .lni-steam::before {
     content: "\ebb8";
 }

 .lni-sthethoscope::before {
     content: "\ebb9";
 }

 .lni-stop::before {
     content: "\ebba";
 }

 .lni-strikethrough::before {
     content: "\ebbb";
 }

 .lni-stripe::before {
     content: "\ebbc";
 }

 .lni-stumbleupon::before {
     content: "\ebbd";
 }

 .lni-sun::before {
     content: "\ebbe";
 }

 .lni-support::before {
     content: "\ebbf";
 }

 .lni-surf-board::before {
     content: "\ebc0";
 }

 .lni-suspect::before {
     content: "\ebc1";
 }

 .lni-swift::before {
     content: "\ebc2";
 }

 .lni-syringe::before {
     content: "\ebc3";
 }

 .lni-tab::before {
     content: "\ebc4";
 }

 .lni-tag::before {
     content: "\ebc5";
 }

 .lni-target-customer::before {
     content: "\ebc6";
 }

 .lni-target-revenue::before {
     content: "\ebc7";
 }

 .lni-target::before {
     content: "\ebc8";
 }

 .lni-taxi::before {
     content: "\ebc9";
 }

 .lni-teabag::before {
     content: "\ebca";
 }

 .lni-telegram-original::before {
     content: "\ebcb";
 }

 .lni-telegram::before {
     content: "\ebcc";
 }

 .lni-text-align-center::before {
     content: "\ebcd";
 }

 .lni-text-align-justify::before {
     content: "\ebce";
 }

 .lni-text-align-left::before {
     content: "\ebcf";
 }

 .lni-text-align-right::before {
     content: "\ebd0";
 }

 .lni-text-format-remove::before {
     content: "\ebd4";
 }

 .lni-text-format::before {
     content: "\ebd1";
 }

 .lni-thought::before {
     content: "\ebd2";
 }

 .lni-thumbs-down::before {
     content: "\ebd3";
 }

 .lni-thumbs-up::before {
     content: "\ebd5";
 }

 .lni-thunder-alt::before {
     content: "\ebd6";
 }

 .lni-thunder::before {
     content: "\ebd7";
 }

 .lni-ticket-alt::before {
     content: "\ebd8";
 }

 .lni-ticket::before {
     content: "\ebd9";
 }

 .lni-tiktok::before {
     content: "\ebda";
 }

 .lni-timer::before {
     content: "\ebdb";
 }

 .lni-tounge::before {
     content: "\ebdc";
 }

 .lni-train-alt::before {
     content: "\ebdd";
 }

 .lni-train::before {
     content: "\ebde";
 }

 .lni-trash-can::before {
     content: "\ebdf";
 }

 .lni-travel::before {
     content: "\ebe0";
 }

 .lni-tree::before {
     content: "\ebe1";
 }

 .lni-trees::before {
     content: "\ebe2";
 }

 .lni-trello::before {
     content: "\ebe3";
 }

 .lni-trowel::before {
     content: "\ebe4";
 }

 .lni-tshirt::before {
     content: "\ebe5";
 }

 .lni-tumblr::before {
     content: "\ebe6";
 }

 .lni-twitch::before {
     content: "\ebe7";
 }

 .lni-twitter-filled::before {
     content: "\ebe8";
 }

 .lni-twitter-original::before {
     content: "\ebe9";
 }

 .lni-twitter::before {
     content: "\ebea";
 }

 .lni-ubuntu::before {
     content: "\ebeb";
 }

 .lni-underline::before {
     content: "\ebec";
 }

 .lni-unlink::before {
     content: "\ebed";
 }

 .lni-unlock::before {
     content: "\ebee";
 }

 .lni-unsplash::before {
     content: "\ebef";
 }

 .lni-upload::before {
     content: "\ebf0";
 }

 .lni-user::before {
     content: "\ebf1";
 }

 .lni-users::before {
     content: "\ebf6";
 }

 .lni-ux::before {
     content: "\ebf2";
 }

 .lni-vector::before {
     content: "\ebf3";
 }

 .lni-video::before {
     content: "\ebf4";
 }

 .lni-vimeo::before {
     content: "\ebf5";
 }

 .lni-visa::before {
     content: "\ebf7";
 }

 .lni-vk::before {
     content: "\ebf8";
 }

 .lni-volume-high::before {
     content: "\ebf9";
 }

 .lni-volume-low::before {
     content: "\ebfa";
 }

 .lni-volume-medium::before {
     content: "\ebfb";
 }

 .lni-volume-mute::before {
     content: "\ebfc";
 }

 .lni-volume::before {
     content: "\ebfd";
 }

 .lni-wallet::before {
     content: "\ebfe";
 }

 .lni-warning::before {
     content: "\ebff";
 }

 .lni-website-alt::before {
     content: "\ec00";
 }

 .lni-website::before {
     content: "\ec01";
 }

 .lni-wechat::before {
     content: "\ec02";
 }

 .lni-weight::before {
     content: "\ec03";
 }

 .lni-whatsapp::before {
     content: "\ec04";
 }

 .lni-wheelbarrow::before {
     content: "\ec05";
 }

 .lni-wheelchair::before {
     content: "\ec06";
 }

 .lni-windows::before {
     content: "\ec07";
 }

 .lni-wordpress-filled::before {
     content: "\ec08";
 }

 .lni-wordpress::before {
     content: "\ec09";
 }

 .lni-world-alt::before {
     content: "\ec0a";
 }

 .lni-world::before {
     content: "\ec0c";
 }

 .lni-write::before {
     content: "\ec0b";
 }

 .lni-xbox::before {
     content: "\ec0d";
 }

 .lni-yahoo::before {
     content: "\ec0e";
 }

 .lni-ycombinator::before {
     content: "\ec0f";
 }

 .lni-yen::before {
     content: "\ec10";
 }

 .lni-youtube::before {
     content: "\ec13";
 }

 .lni-zip::before {
     content: "\ec11";
 }

 .lni-zoom-in::before {
     content: "\ec12";
 }

 .lni-zoom-out::before {
     content: "\ec14";
 }
</style>