<template>
  <div style="margin-left: 12px; margin-right: 12px;">
      <a :href="link" target="_blank">{{name}}</a>
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    data() {
      return {
        name: "",
        link: "",
      }
    },
    created: function() {
        this.name = this.params.value.name;
        this.link = this.params.value.link;
      },
    components: {
    },
    methods: {
    }
  });
</script>

<style>
</style>