import Vue from 'vue'
import VueRouter from 'vue-router'
import GridLogin from './components/login/GridLogin.vue'
import GridWelcome from './components/GridWelcome.vue'
import GridPage from './components/GridPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'page',
        component: GridPage,
        meta: { requiresAuth: true } // cette route nécessite une authentification
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: GridWelcome
    },
    {
        path: '/login',
        name: 'login',
        component: GridLogin
    },
    {
        path: '*',
        redirect: '/welcome'
    }
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        if (document.cookie.indexOf("accessToken") !== -1) {
            var accessToken = document.cookie
                .split('; ')
                .find(row => row.startsWith('accessToken='))
                .split('=')[1];
        } else{
            accessToken = ""
        }
        fetch('api.php?run=check', {
            headers: { 'Authorization': 'Bearer ' + accessToken }
        })
            .then(response => {
                console.log(response)
                // console.log(response.json())
                if (!response) {
                    throw new Error('request failed')
                }
                return response.json()
            })
            .then(data => {
                console.log(data)
                if (data && data.data.isAuthenticated) {
                    next()
                } else {
                    next('/welcome')
                }
            })
            .catch(error => {
                console.log("catch welcome")
                next('/welcome')
            })
    } else {
        next()
    }
})

export default router
