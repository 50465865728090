<template>
  <ag-grid-vue :style="{ width, height }" class="ag-theme-alpine" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
    :rowData="rowData" :suppressRowClickSelection="true" :suppressCellFocus="true" :rowSelection="rowSelection"
    @grid-ready="onGridReady" :alwaysShowVerticalScroll="true" :suppressHorizontalScroll="true"
    :suppressScrollOnNewData="true">
  </ag-grid-vue>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import GridValueStatusExecution from "./GridValueStatusExecution.vue";
import GridValueStatusCheck from "./GridValueStatusCheck.vue";
import GridValueRegular from "../utils/GridValueRegular.vue";
import GridValueFactor from "./GridValueFactor.vue";
import { check, status, size, factor } from "../../common.js";

export default {
  components: {
    AgGridVue,
    GridValueStatusExecution,
    GridValueStatusCheck,
    GridValueRegular,
  },
  data: function () {
    return {
      columnDefs: [
        { field: "graph", headerName: "Graph", width: 200, cellRendererFramework: GridValueRegular },
        { field: "size", headerName: "Size", width: 70, cellRendererFramework: GridValueRegular },
        { field: "statusNeo4j", headerName: "Neo4j time (N)", width: 155, cellRendererFramework: GridValueStatusExecution },
        { field: "statusICY", headerName: "ICY time (I)", width: 155, cellRendererFramework: GridValueStatusExecution },
        { field: "check", headerName: "Results", width: 125, cellRendererFramework: GridValueStatusCheck },
        { field: "factor", headerName: "N:I", width: 75, cellRendererFramework: GridValueFactor },
      ],
      defaultColDef: {
        sortable: true,
        suppressMovable: true
      },
      width: '800px',
      height: '100%',
      rowData: null,
      gridApi: null,
      gridColumnApi: null,
      rowSelection: 'single',
      scrollTop: 0,
    }
  },
  created: function () {

    setInterval(() => {

      if (document.cookie.indexOf("accessToken") !== -1) {
        var accessToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('accessToken='))
          .split('=')[1];
      } else {
        accessToken = ""
      }
      fetch('api.php?run=status', {
        headers: { 'Authorization': 'Bearer ' + accessToken },
        method: 'GET',
      })
        .then(response => {
          if (!response) {
            throw new Error('request failed')
          }

          return response.json()
        })
        .then(data => {
          console.log(data);

          this.rowData = data.map((item) => {

            // define status
            var statusNeo4j = { status: null, time: item.neo4j_execution_time };
            if (item.neo4j_status == "Running") {
              statusNeo4j.status = status.Running;
            } else if (item.neo4j_status == "Waiting") {
              statusNeo4j.status = status.Waiting;

            } else if (item.neo4j_status == "") {
              statusNeo4j.status = status.Empty;
            }
            else {
              statusNeo4j.status = status.Done;
            }
            var statusICY = { status: null, time: item.icy_execution_time };
            if (item.icy_status == "Running") {
              statusICY.status = status.Running;
            } else if (item.icy_status == "Waiting") {
              statusICY.status = status.Waiting;
            } else if (item.icy_status == "") {
              statusICY.status = status.Empty;
            }
            else {
              statusICY.status = status.Done;
            }
            var result = 2;
            if (item.result == null) { result = 2 }
            else{
              if (item.result.toLowerCase() == "same") { result = 0; }
              if (item.result.toLowerCase() == "different") { result = 1 }
              else { result = 2 }
            } 

            return {
              graph: item.graph.name,
              size: size(item.graph.nodes, item.graph.edges),
              edges: item.graph.edges,
              nodes: item.graph.nodes,
              statusNeo4j: statusNeo4j,
              statusICY: statusICY,
              factor: (statusICY.time === null || statusNeo4j.time === null) ? "" : factor(statusICY.time, statusNeo4j.time),
              check: result,
              created_at: new Date(item.created_at),
            }
          }).sort((a, b) => b.created_at.getTime() - a.created_at.getTime());

        })
        .catch(error => {
          console.error(error);
          // this.$router.push("/welcome");
        });

    }, 500);
  },
  mounted: function () {
    console.log(`the component is now mounted.`);
  },
  methods: {
    onGridReady: function (params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      let widthTotal = this.columnDefs.reduce((partialSum, a) => partialSum + a.width, 2);
      this.width = widthTotal + "px";

    },
  }
}
</script>

<style>
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

body,
html {
  height: 100%;
}

.execution-stats-names {
  flex: 0 0 100px !important;
}

.execution-stats-values {
  flex: 1 0 !important;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.btn:active {
  transform: scale(0.95);
}
</style>
